import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import DefaultLayout from "/builds/everyone/dcdcweb-saturday/src/components/Layout/index.js";
const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = DefaultLayout;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" Layout={this.layout} layoutProps={Object.assign({}, layoutProps, props)} components={components}>

      <MDXTag name="p" components={components}><a className="gatsby-resp-image-link" href="/static/5c1fae8bc67b9acad434302ed48ce2cd/82feb/TPEventSocialPost_0307_V03.jpg" style={{
          "display": "block"
        }} target="_blank" rel="noopener">
    <span className="gatsby-resp-image-wrapper" style={{
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1080px"
          }}>
    <span className="gatsby-resp-image-background-image" style={{
              "paddingBottom": "142.6%",
              "position": "relative",
              "bottom": "0px",
              "left": "0px",
              "backgroundSize": "cover",
              "display": "block"
            }}></span>
    <img className="gatsby-resp-image-image" style={{
              "width": "100%",
              "height": "100%",
              "margin": "0px",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0px",
              "left": "0px",
              "boxShadow": "inset 0px 0px 0px 400px white"
            }} alt="TPEventSocialPost 0307 V03" title="" src="/static/5c1fae8bc67b9acad434302ed48ce2cd/54f40/TPEventSocialPost_0307_V03.jpg" srcSet="/static/5c1fae8bc67b9acad434302ed48ce2cd/092e8/TPEventSocialPost_0307_V03.jpg 334w,
/static/5c1fae8bc67b9acad434302ed48ce2cd/d6e26/TPEventSocialPost_0307_V03.jpg 668w,
/static/5c1fae8bc67b9acad434302ed48ce2cd/54f40/TPEventSocialPost_0307_V03.jpg 1336w,
/static/5c1fae8bc67b9acad434302ed48ce2cd/94198/TPEventSocialPost_0307_V03.jpg 2004w,
/static/5c1fae8bc67b9acad434302ed48ce2cd/4a6ed/TPEventSocialPost_0307_V03.jpg 2672w,
/static/5c1fae8bc67b9acad434302ed48ce2cd/c9854/TPEventSocialPost_0307_V03.jpg 4008w,
/static/5c1fae8bc67b9acad434302ed48ce2cd/82feb/TPEventSocialPost_0307_V03.jpg 4500w" sizes="(max-width: 1080px) 100vw, 1080px" />
  </span>
  </a></MDXTag>
      <MDXTag name="h1" components={components}>{`Join us for Integrating Identity Safely in Web 3.0`}</MDXTag>
      <MDXTag name="h4" components={components}>{`with `}<MDXTag name="a" components={components} parentName="h4" props={{
          "href": "http://www.transition-partners.co.uk/"
        }}>{`Transition Partners`}</MDXTag></MDXTag>
      <MDXTag name="h3" components={components}>{`When: July 11th`}</MDXTag>
      <MDXTag name="h3" components={components}>{`Where: `}<MDXTag name="a" components={components} parentName="h3" props={{
          "href": "https://the-lostandfound.co.uk/restaurant/leeds-club"
        }}>{`Lost & Found, Leeds`}</MDXTag></MDXTag>
      <MDXTag name="h3" components={components}>{`Themes and Topics: Self Sovereign Identity, Web 3.0, Distributed Ledger, Semantic Web`}</MDXTag>
      <MDXTag name="p" components={components}>{`We will announce more detail about this event once we have them to share.`}</MDXTag>
           </MDXTag>;
  }

}
export const _frontmatter = {};
    